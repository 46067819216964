// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-blog-post-page-js": () => import("./../../../src/components/blog/blog-post-page.js" /* webpackChunkName: "component---src-components-blog-blog-post-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-me-js": () => import("./../../../src/pages/contact-me.js" /* webpackChunkName: "component---src-pages-contact-me-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-skills-i-have-js": () => import("./../../../src/pages/skills-i-have.js" /* webpackChunkName: "component---src-pages-skills-i-have-js" */),
  "component---src-pages-what-im-looking-for-js": () => import("./../../../src/pages/what-im-looking-for.js" /* webpackChunkName: "component---src-pages-what-im-looking-for-js" */),
  "component---src-pages-who-i-am-js": () => import("./../../../src/pages/who-i-am.js" /* webpackChunkName: "component---src-pages-who-i-am-js" */),
  "component---src-pages-work-ive-done-index-js": () => import("./../../../src/pages/work-ive-done/index.js" /* webpackChunkName: "component---src-pages-work-ive-done-index-js" */),
  "component---src-pages-work-ive-done-product-design-js": () => import("./../../../src/pages/work-ive-done/product-design.js" /* webpackChunkName: "component---src-pages-work-ive-done-product-design-js" */),
  "component---src-pages-work-ive-done-ui-components-js": () => import("./../../../src/pages/work-ive-done/ui-components.js" /* webpackChunkName: "component---src-pages-work-ive-done-ui-components-js" */),
  "component---src-pages-work-ive-done-ux-design-js": () => import("./../../../src/pages/work-ive-done/ux-design.js" /* webpackChunkName: "component---src-pages-work-ive-done-ux-design-js" */),
  "component---src-pages-work-ive-done-visual-design-js": () => import("./../../../src/pages/work-ive-done/visual-design.js" /* webpackChunkName: "component---src-pages-work-ive-done-visual-design-js" */),
  "component---src-pages-work-ive-done-web-animations-js": () => import("./../../../src/pages/work-ive-done/web-animations.js" /* webpackChunkName: "component---src-pages-work-ive-done-web-animations-js" */),
  "component---src-pages-work-ive-done-writing-js": () => import("./../../../src/pages/work-ive-done/writing.js" /* webpackChunkName: "component---src-pages-work-ive-done-writing-js" */)
}

